/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import Button from "../../components/Button/Button";
import CardValores from "../../components/CardValores/CardValores";
import Itinerarios from "../../components/Itinerarios/Itinerarios";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgEnsinoMedio from "../../assets/images/etapas-de-aprendizagem/ensino-medio/ensino-medio.png";
import itinerariosFormativos from "../../data/etapas-de-aprendizagem/ensino-medio/itinerarios-formativos.json";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function EnsinoMedio() {
	return (
		<Layout>
			<SEO title="Ensino Médio" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="pt-0 pt-md-5  order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgterciary-gradient d-none d-md-block">ensino médio</h1>
						<h1
							className="pageTitle fgterciary-gradient d-block d-md-none"
							style={{ fontSize: "3.7em" }}
						>
							ensino médio
						</h1>
						<h3 className="fgterciary-gradient">nosso propósito</h3>
						<p className="text-justify">
							Na Escola de Aplicação Feevale os conhecimentos e as habilidades
							construídos no Ensino Fundamental são aprofundados e expandidos,
							garantindo aos estudantes a aprendizagem necessária para serem
							capazes de ler a realidade, enfrentar novos desafios e tomar
							decisões éticas fundamentadas em conhecimento. Em continuidade a
							esse propósito, no Ensino Médio, os estudantes desenvolvem as
							competências que lhes permitem se inserir de forma ativa, crítica,
							criativa e responsável em um mundo de trabalho cada vez mais
							complexo e imprevisível. Além disso, a Escola atua na construção
							de um postura empreendedora, ética e responsável.
						</p>
						<h3 className="fgterciary-gradient pt-3">horários</h3>
						<p className="mb-0">
							<span style={{ fontWeight: 500 }}>Para o <b>primeiro</b> ciclo do ensino médio:</span>:
						</p>
						<p className="mb-0">
							// <span style={{ fontWeight: 500 }}>Formação Geral Básica</span>:
							das 7h10 às 12h10
						</p>
						<p>
							// <span style={{ fontWeight: 500 }}>Itinerários Formativos</span>
							: opções manhã
						</p>
						<p className="mb-0">
							<span style={{ fontWeight: 500 }}>Para o <b>segundo</b> ciclo do ensino médio:</span>:
						</p>
						<p className="mb-0">
							// <span style={{ fontWeight: 500 }}>Formação Geral Básica</span>:
							das 9h15 às 12h
						</p>
						<p>
							// <span style={{ fontWeight: 500 }}>Itinerários Formativos</span>
							: opções manhã e tarde
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="pt-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgEnsinoMedio} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-4 pt-md-0">
							conheça as atividades de contraturno
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="pb-2 pt-3">
						<Button
							type="ensino"
							to="/contraturno/atividades-extracurriculares"
							text="Atividades extracurriculares"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-2 pt-3">
						<Button type="ensino" to="/contraturno/oficinas" text="Oficinas" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-4">itinerários formativos</h3>
						<p>
							Nossos estudantes customizam a sua trajetória escolar com os
							itinerários formativos, que são organizados por meio da oferta de
							diferentes arranjos curriculares, conforme a relevância para o
							contexto local e que sejam de interesse dos estudantes. Contemplam
							as quatro áreas de conhecimento e a formação técnica e
							profissional.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<Itinerarios itinerarios={itinerariosFormativos} />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-4 pt-md-0">valores para alunos de rematrículas (2º e 3º ano)</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p>
							// Formação Geral Básica + 2 Itinerários Formativos (1 por
							semestre)
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						amount="12"
						value="1.644,28"
						bgColor="bgterciary-gradient"
					/>
					<CardValores
						amount="11"
						value="1.794,95"
						bgColor="bgterciary-gradient"
					/>
					<CardValores
						amount="10"
						value="1.974,44"
						bgColor="bgterciary-gradient"
					/>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient mt-3 pt-md-0">valores para alunos ingressantes no primeiro ano</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p>
							// Formação Geral Básica + 2 Itinerários Formativos (1 por
							semestre)
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						amount="12"
						value="1.912,91"
						bgColor="bgterciary-gradient"
					/>
					<CardValores
						amount="11"
						value="2.086,81"
						bgColor="bgterciary-gradient"
					/>
					<CardValores
						amount="10"
						value="2.295,50"
						bgColor="bgterciary-gradient"
					/>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
